
@import "@design";

.ios,
.md {
    .p-index {
        --f7-navbar-height: var(--navbar-height);

        .page-content {
            overflow-x: hidden;
        }

        .route-card {
            padding: 8px 23px;

            .bar-inner {
                padding: 7px 0
            }
        }

        @include media-up(376) {
            --page-bg-2area-size: #{rwd-val(376px, 290px, 768px, 520px)};
        }
    }
}

.swiper-overlay {
    display: block;
    width: 100%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzc1IiBoZWlnaHQ9IjYzLjMzMyIgdmlld0JveD0iMCAwIDM3NSA2My4zMzMiPjxkZWZzPjxzdHlsZT4uYSwuYywuZHtmaWxsOiNmZmY7fS5ie29wYWNpdHk6MC44O2NsaXAtcGF0aDp1cmwoI2EpO30uY3tvcGFjaXR5OjAuMzt9LmR7b3BhY2l0eTowLjU7fTwvc3R5bGU+PGNsaXBQYXRoIGlkPSJhIj48cGF0aCBjbGFzcz0iYSIgZD0iTTQ0NSwzMDk3LjkxM3MxNDMuMjUsMy4yLDIzNywwLDEzOC0xMi43ODYsMTM4LTEyLjc4NlYzMDM2SDQ0NVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NDUgLTI3MTkpIi8+PC9jbGlwUGF0aD48L2RlZnM+PGcgY2xhc3M9ImIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTMxNykiPjxwYXRoIGNsYXNzPSJjIiBkPSJNODE5LDMzMTNjLTEzOC0xMjYtMzc0LDAtMzc0LDB2NTJIODE5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ0NSAtMjk0MCkiLz48cGF0aCBjbGFzcz0iZCIgZD0iTTYzOSwzMzM0Yy01OC00OS0xOTQtNjAtMTk0LTYwdjkySDYzOVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NDUgLTI5NDApIi8+PC9nPjwvc3ZnPg==);
    background-position: center bottom;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 10%;
    z-index: 10;
    padding-top: 16.804%;
}

// Swiper Banner
.skeleton-block {
    position: relative;
    padding-top: calc(358 / 768 * 100%);

    .swiper-container {
        @include absolute(0, 0, 0, 0);
    }

    ~.wav {
        height: 32px;
        min-height: 32px;
        // background: url('@/assets/images/general/wave.png');
        background-repeat: repeat-x;
        background-size: auto 100%;
        width: 100%;
        margin-top: -10px;
        position: relative;
        z-index: 1;
    }
}


.index-swiper {
    margin-bottom: calc(17 / 14 * 1rem);
    filter: drop-shadow(0 3px 6px rgba(#000, 0.16));

    .swiper-slide {
        overflow: hidden;
        transition: transform .3s;
        margin-bottom: 7px;

        a {
            display: block;
            position: relative;

            &:before {
                content: '';
                display: block;
                // padding-top: calc(330 / 768 * 100%);
            }

            img {
                margin: auto;
                max-width: 100%;
                border-radius: 16px;
            }
        }

        &:not(.swiper-slide-active) {
            transform: translateY(7px);
            opacity: .5;
            transform: scale(.8);
        }
    }

    @include media-up(375) {
        .swiper-slide {
            a {
                img {
                    border-radius: rwd-val(375px, 16px, 768px, 32px);
                }
            }
        }
    }

    @include media-up(768) {
        .swiper-slide {
            a {
                img {
                    border-radius: 32px;
                }
            }
        }
    }
}


.index-menu-bed {
    position: relative;
    margin-bottom: calc(15 / 14 * 1rem);
    display: flex;

    &:before {
        content: '';
        display: block;
        // padding-top: calc(170 / 345 * 100%);
    }

    .link-inner{
       width: 100%;
       background: #fff;
       border-radius: 16px;
       padding:15px 0;
       box-shadow: 0 1px 16px #1098CB4D;
    }

    .bed-inner {
        // @include absolute(0, 0, 0, 0);
    }

    .bed-bg {
        display: block;
        width: 100%;
        filter: drop-shadow(#{$shadow-light});
        // @include absolute(0, 0, 0, 0);
    }

    .btn-link-disable-overlay {
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(216 / 345 * 100%);
    }

    .bed-link {
        color: $color-font;
        text-align: center;
        position: relative;
        z-index: 1;
        width: calc(50% - 5px);

        img {
            height: 40px;
            width: auto;
        }

        .link-txt {
            font-size: calc(16 / 14 * 1rem);
            letter-spacing: calc(3.2 / 16 * 1em);
            font-weight: 500;
            margin-top: 6px;
            line-height: 18px;
            min-height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @include media-up(376) {
            // .img-1 {
            //     width: rwd-val(376px, 26px, 768px, 50px);
            // }

            // .img-2 {
            //     width: rwd-val(376px, 40px, 768px, 80px);
            // }

            // .img-3 {
            //     width: rwd-val(376px, 28px, 768px, 56px);
            // }

            // .img-4 {
            //     width: rwd-val(376px, 43px, 768px, 86px);
            // }

            // .link-txt {
            //     font-size: rwd-val(376px, 16px, 768px, 24px);
            // }
        }
    }
    .bed-link+.bed-link{ margin-left: 10px;}

    .bed-link_disabled {
        opacity: 0.3;
        pointer-events: none;

        img {
            filter: grayscale(1)
        }
    }

    .link-1 {

    }

    .link-2 {

    }

    // .link-3 {
    //     bottom: 0;
    //     top: 50%;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     width: 50%;
    // }

    // .link-4 {
    //     top: 17%;
    //     bottom: 17%;
    //     left: 0;
    //     width: 32%;
    // }
}

.ios,
.md {
    .navbar {
        .navbar-inner {
            .round-icon-btn {
                min-width: auto;
                border: 1px solid #fff;
                border-radius: 50%;
                background: $color-primary;
                @include flex(center, center);
                width: calc(26 / 14 * 1rem);
                height: calc(26 / 14 * 1rem);
                font-size: calc(17 / 14 * 1rem);
                color: #fff;
                box-shadow: $shadow-light;
            }
        }
    }

    .navbar-index {
        --f7-navbar-height: var(--navbar-height);

        .title {
            font-weight: bold;
            font-size: calc(20 / 14 * 1rem);
        }
    }
}

.fleet-info-preview-box {
    --side-width: 1.8em;

    margin-bottom: calc(17 / 14 * 1rem);
    position: relative;
    filter: drop-shadow(#{$shadow-light});

    .box-back {
        background: $color-primary;
        border-radius: 16px;
        @include absolute(0, 0, 0, 0);
    }

    .box-front {
        background: #fff;
        border-radius: 16px;
        margin-left: var(--side-width);
        position: relative;

        display: flex;
        padding: 15px 10px;

        .front-left {
            flex-grow: 1;
            display: block;
        }

        .front-right {
            flex-grow: 7;
            max-width: 280px;
        }
    }

    .box-status {
        font-weight: bold;
        max-width: var(--side-width);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
    }


    .box-info-item {
        display: flex;
        align-items: flex-start;

        +.box-info-item {
            margin-top: calc(10 / 14 * 1rem);
        }

        .item-title {
            font-size: 1rem;
            letter-spacing: calc(3.5 / 14 * 1em);
            font-weight: 500;
            background: rgba($color-primary, 0.3);
            color: $color-font;
            padding: 2px 6.5px;
            border-radius: 8px;
            min-width: 45px;
            box-sizing: border-box;
            text-align: center;
            margin-right: 9px;
        }

        .item-content {
            font-size: calc(16 / 14 * 1rem);
            color: $color-font-gray;
            padding-top: 0.05em;
            font-weight: 500;
        }
    }

    .box-tools {
        display: flex;
        align-items: stretch;
        width: 100%;
        height: 100%;

        .tools-item {
            flex-grow: 1;
            text-align: center;
            @include flex(center, center);
            position: relative;

            &.active-state {
                &:before {
                    opacity: 1;
                }
            }

            &:before {
                content: '';
                display: block;
                @include absolute(0, 3px, 0, 3px);
                background: rgba($color-primary, 0.1);
                opacity: 0;
                transition: .3s;
                border-radius: 7px;
            }

            * {
                pointer-events: none;
            }

            +.tools-item {
                border-left: 2px dotted $color-border;
            }
        }

        .tools-msg {
            position: relative;
            margin-right: 5px;

            img {}

            .muki-num-badge {
                right: -17px;
            }
        }

        @include media-up(376) {
            .img-msg {
                width: rwd-val(376px, 28px, 768px, 56px);
            }

            .img-phone {
                width: rwd-val(376px, 27px, 768px, 54px);
            }
        }
    }
}

.fast-appoint-wrap{
    border:1px #4D9FC2 solid;
    background: #fff;
    overflow: hidden;
    border-radius: 16px;

    .base-list{
        border-top:2px #c9e2ec solid;
    }
}

.fast-appoint {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    color: #4D9FC2;

    
    

    .appoint-title {
        font-weight: bold;
        font-size: calc(18 / 14 * 1rem);
        letter-spacing: calc(1.26 / 18 * 1em);
        padding: 10px 0 0 10px;
    }

    .muki-tabbar.sty-tiny-space{
        padding:0;
    }

    .toolbar {
        width: 125px;
        margin-bottom: 0;
        background: #EDF5F9;
        border: 1px #D8E0E3 solid;
        margin: 10px 10px 0 0;

        .tab-link {
            // semibold
            font-weight: 600;
        }
        .tab-link-content{
            text-shadow: none;
            font-size: 14px;
            font-weight: normal;
            color: #000;
        }
        .tab-link-acitve{
            color:#fff;
        }
        .tab-link-bg-hilighter{
            background: #4D9FC2;
        }
    }

    
}

.new-logo{
    display: inline-block;
    background: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: relative;
    overflow: hidden;
    img{
       position: absolute;
       left: 50%;
       top: 50%;
       transform: translate(-50%,-50%); 
       width: 40px;
       height: 40px;
    }
}

.bar-inner{
    border: 0 !important;
}

.list-item{
    border-bottom: 1px #ddd dashed;
}
