
@import "~@/css/design";

.route-visual-line{
    // --route-visual-line-border: 4; // px
    // --route-visual-line-gap: 2; // px
    // --route-visual-line-border-portion: calc( (var(--route-visual-line-border) / var(--route-visual-line-unit-length)) * 100%);
    // --route-visual-line-gap-portion: calc( (var(--route-visual-line-gap) / var(--route-visual-line-unit-length)) * 100%);
    // --route-visual-line-unit-length: calc((var(--route-visual-line-border) + var(--route-visual-line-gap)));


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    img {
        flex-shrink: 0;
        &:first-child{
            height: 1rem;
            margin-bottom: 3px;
        }
        &:last-child{
            height: calc( 15.6 / 14 * 1rem);
            margin-top: 3px;
        }
    }
    .dash-line{
        width: 3px;
        height: 10px;
        margin-left: -0.5px;
        background: #4D9FC2;
    }

}

