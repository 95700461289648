
@import "~@/css/design";

.route-card-list{
    box-shadow: 0 5px 26px #00000008; 
}

.route-card{
    --visual-line-start: 0px;
    --visual-line-end: 0px;

    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 5px 26px #00000008; 
    border-radius: 16px;
    padding: 14px 23px;
    position: relative;
    font-size: calc( 16 / 14 * 1rem);
    letter-spacing: 0.1em;
    font-weight: 500;
    opacity: 0;
    color: #000000;
    user-select: none;

    .js-marquee-wrapper{
        --js-marquee-wrapper-length: -400px;
        width: 100000px;
        transform: translateX(0px);
        animation: 8.31849s linear 0s infinite normal none running marqueeAnimation-377641;
        .js-marquee{
            margin-right: 50px;
            float: left;
        }

        @keyframes marqueeAnimation-377641  { 100%  {transform: translateX(var(--js-marquee-wrapper-length))} }
    }


    &.is-inited{
        opacity: 1;
    }

    &.sty-active-state{
        transition: opacity .3s;
        &.active-state{
            opacity: 0.8;
        }
    }

    &.sty-no-shadow{
        box-shadow: none;
    }

    &.tail-line{
        .route-bar:last-child{
            .bar-inner{
                &:before{
                    content:'';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: auto;
                    right: 0;
                    bottom : 0;
                    height: 1px;
                    background: linear-gradient(to right, #ADADAD 0 50%, #fff 50.01% );
                    background-size: 4px 100%;
                    background-repeat: repeat-x;
                }
            }
        }
    }

    &.sty-slip{
        padding: 0;
        box-shadow: none;
        font-size: calc( 18 / 14 * 1rem);
        .route-bar{
            .bar-inner{
                padding-top: 10px;
                padding-bottom: 15px;
            }
        }
    }

    .card-inner{
        display: flex;
        position: relative;
    }

    .route-visual-line{
        position: absolute;
        top: var(--visual-line-start);
        bottom: var(--visual-line-end);
    }

    .is-no-marquee{
        .js-marquee:last-child{
            display: none;
        }
    }

    &.click-play{
        .bar-inner{
            .js-marquee-wrapper, .bar-address{
                animation-delay: 0s!important;
            }
            &:not(.is-play), &.is-no-marquee{
                .js-marquee-wrapper{
                    animation: none!important;
                }
            }
            .bar-address.is-no-marquee{
                .js-marquee:last-child{
                    display: none;
                }
            }
            .is-no-marquee{
                .js-marquee-wrapper{
                    animation: none!important;
                }
            }
        }
    }

    &.taphold-play{
        .bar-address{
            .js-marquee-wrapper {
                animation-delay: 0.6s!important;
            }
            .js-marquee{
                pointer-events:none;
            }
            &:not(.active-state) , &.is-no-marquee{
                .js-marquee-wrapper{
                    animation: none!important;
                }
            }
            .is-no-marquee{
                .js-marquee-wrapper{
                    animation: none!important;
                }
            }
        }
    }

    .route-bar{
        position: relative;
        margin-left: calc(24 / 14 * 1rem);
        .bar-address{
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            font-weight: normal;
            position: relative;
            &:after{
                content:'';
                display: block;
                position: absolute;
                top:0;
                right: 0;
                bottom: 0;
                width: 1em;
                background: linear-gradient(to left, rgba(255, 255, 255, 0.9) 0 10%, rgba(255, 255, 255, 0) 100%);
            }
        }

        .bar-hint{
            background: #F8F8F8;
            border-radius: 8px;
            padding: 4px 8px;
            margin-top: 8px;
            font-weight: normal;
        }

        .bar-inner{
            padding: 9px 0;
            position: relative;
            border-bottom:0;
            .js-marquee-wrapper, .bar-address{
                // pointer-events: none;
                user-select: none;
            }
        }

        + .route-bar{
            .bar-inner{
                @include project-border((position:top, color:#ADADAD,  pesudo: after));
            }
        }
    }

    .card-list{
        flex-grow: 1;
        min-width: 0;
    }

    .card-dots{
        display:flex;
        flex-direction: column;
        position: absolute;
        right: calc( 100% + 14px + 3px);
        top: 100%;
        transform: translateX(50%) translateY(-50%);
        .dot{
            width: 3px;
            height: 3px;
            background: #ADADAD;
            + .dot{
                margin-top: 3px;
            }
        }
    }

    .card-date{
        font-size: calc( 12 / 14 * 1rem);
        transform: scale(calc(9 / 12));
        transform-origin: right top;
        color: rgba(#000, 0.5);
        letter-spacing: 0;
        position: absolute;
        top: 2.5px;
        right: 16.7px;
        font-family: var(--ff-poppins);
    }
}

